.footer {
  .footerTop {
    background: #EBF5FB;
    padding: 55px 0;
    font-family: 'futuramedium';

    h3 {
      font-size: 18px;
      line-height: 24px;
      font-family: 'futurabold';
      text-transform: uppercase;
      color: #557A95;

      @media(max-width:1199px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .logoSocial {
      max-width: 305px;
      color: #557A95;

      @media(max-width:991px) {
        max-width: 100%;
        margin-bottom: 30px;
      }
    }

    .logoFoot {
      margin-bottom: 30px;
      color: #557A95;

      @media(max-width:767px) {
        margin-bottom: 20px;
      }
    }

    .socialmedia {
      padding-top: 10px;
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 8px;

        a {
          background: #FFFFFF;
          border: 1px solid #EBF5FB;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: #00709A;

          &:hover {
            background: #00709A;
            color: #fff;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .links {
      @media(max-width:767px) {
        margin-bottom: 30px;
      }

      li {
        font-size: 14px;
        line-height: 22px;
        font-family: 'futuramedium';

        margin-bottom: 18px;

        @media(max-width:767px) {
          margin-bottom: 10px;
        }

        a {
          color: #557A95;

          &:hover {
            color: #525255;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .addressContact {
      li {
        padding-left: 37px;
        position: relative;
        margin-bottom: 22px;
        font-size: 14px;
        line-height: 22px;
        color: #557A95;

        a {
          color: #557A95;

          &:hover {
            color: #525255;
          }
        }

        svg,
        img {
          width: 23px;
          height: 23px;
          position: absolute;
          top: 0;
          left: 0;
        }

        img {
          font-size: 0;
          line-height: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .footerBot {
    padding: 22px 0;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-transform: capitalize;
    font-family: 'futuramedium';
    color: #557A95;
  }
}