.loginLink {
  padding-top: 30px;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  margin-bottom: 0 !important;
  text-align: center;

  color: #5D5C61;
  font-family: 'futuramedium';

  a {
    color: #00709A;

    &:hover {
      color: #5D5C61;
    }
  }
}
.error{
  color: red;
}
#phn_no {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
