.citiesWrap {
    h1 {
        font-family: "Cormorant Garamond", serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 10px;
    }

    .line {
        border-bottom: 1px dashed #999;
        h4 {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.01em;
            text-transform: capitalize;
            font-family: "Cormorant Garamond", serif;
            color: #222222;
        }
    }

    .cities_list{
        padding: 50px 0 0;
        h2, h3, h4, h5, h6{
            font-family: "Cormorant Garamond", serif;
            font-weight: 700;
        }

        h2, h3{
            border-bottom: 1px solid #dddddd;
        }
        .cities_nested{
            padding-left: 30px;
        }
        h3{
            font-size: 22px;
        }
        .cities_inner{
            padding-left: 30px;
            ul{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -10px 20px;
                li{
                    padding: 0 10px;
                    margin-left:50px;
                    font-family: "Cormorant Garamond", serif;
                }
            }
            
            h4{
                margin-bottom: 5px;
                font-size: 19px;
            }

        }
    }
}



