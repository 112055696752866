.stepsec {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -10px;

    @media (max-width: 767px) {
      margin-bottom: -30px;
    }

    li {
      flex-basis: 20%;
      max-width: 20%;
      padding: 0 10px;
      text-align: center;
      font-size: 14px;
      line-height: 19px;
      text-align: center;

      color: #525255;
      font-family: "futurabook";
      position: relative;

      @media (max-width: 991px) {
        font-size: 12px;
        line-height: 17px;
      }

      @media (max-width: 767px) {
        flex-basis: 50%;
        max-width: 50%;
        margin-bottom: 30px;
      }

      .sigPad {
        width: 100%;
        height: 100%;
        background-color: aqua;
      }

      .number {
        display: inline-block;
        margin-bottom: 20px;

        // position: relative;
        span {
          position: relative;
          border-radius: 50%;
          margin-bottom: 14px;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          background: #ffffff;
          border: 1px solid #dfdfdf;
          width: 50px;
          height: 50px;
          font-family: "futuramedium";
        }

        &::before {
          content: "";
          position: absolute;
          left: 68%;
          top: 25px;
          width: 96%;
          height: 5px;
          background: #c4c4c4;
          border-radius: 5px;

          @media (max-width: 767px) {
            display: none;
          }
        }
      }

      &:last-child {
        .number {
          &:before {
            display: none;
          }
        }
      }

      &.active {
        .number {
          &:before {
            background: #31719c;
          }

          span {
            background: #31719c;
            color: #fff;
          }
        }
      }

      &.half_active {
        .number {
          &:before {
            background: #31719c;
          }

          span {
            background: linear-gradient(90deg, #31719c 50%, #ffff 50%);
            color: black;
          }
        }
      }
    }
  }
}

.selectaddPrice {
  padding-bottom: 100px;

  .selectAddress {
    background: url(../../images/selectbg.svg) no-repeat 0 0;
    background-size: cover;
    border-radius: 10px;
    padding: 24px 24px;
    margin-bottom: 15px;
    .dropdown-menu{
      .dropdown-item{
        display: block;
        max-width: 98%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
    label {
      color: #ffffff;
    }

    .select-des {
      background: url(../../images/gpsic.svg) no-repeat left 20px center,
        url(../../images/downarr2.svg) no-repeat right 20px center #ffffff;
      box-shadow: 0px 6px 16px rgba(0, 80, 109, 0.08);
      border-radius: 16px;
      color: #000000;
      font-size: 14px;
      line-height: 19px;
      font-family: "futurabook";
      padding: 18px 30px 18px 50px;
      width: 100%;

      position: relative;
      text-align: left;
      @media(max-width: 767px){
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      &::after {
        display: none;
      }

      .dropdown-toggle.btn {
        padding: 11px 0;
        border: 0;

        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        font-family: "futuramedium";
        color: #000000;
        text-align: left;
        background: transparent;

        &:hover,
        &:focus {
          background: transparent;
        }

        &:after {
          display: none;
        }
      }

      .dropdown-menu.show {
        position: absolute;
        margin-left: -49px !important;
        margin-top: 0px !important;
        transform: inherit !important;
        inset: initial !important;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        border: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-radius: 0px 0px 16px 16px;
        padding: 10px 30px;
        padding-right: 0;

        a {
          font-size: 14px;
          line-height: 19px;
          font-family: "futuramedium";
          color: #000000;
          padding: 0;
          padding-right: 10px;
          padding-bottom: 10px;
          margin-bottom: 14px;
          border-bottom: 1px solid #e9e9e9;

          &:hover {
            background: transparent;
            color: #31719c;
          }

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 6px;
            font-size: 14px;
            line-height: 19px;
            text-transform: capitalize;
            font-family: "futuramedium";
            color: #31719c;
          }
        }
      }
    }
  }

  .designDetail {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 13px 11px;

    .row {
      .lftimgslider {
        .slick-slider {
          .slick-slide{
            img{

              height: 250px;
              object-fit: cover;
              object-position: center;
            }
          }
          &.nav {
            margin-top: 10px;

            .slick-slide {
              padding: 0 5px;

              img {
                width: 50px;
                height: 30px;
                object-fit: contain;
                border-radius: 10px;
              }
            }
          }

          img {
            border-radius: 8px;
            width: 100%;

            @media (max-width: 991px) {
              height: 260px;
              object-fit: cover;
            }
          }

          .slick-arrow {
            background: #ffffff;
            box-shadow: 0px 4px 44px #ffdbe1;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            display: flex !important;
            align-self: center;
            justify-content: center;
            color: #31719c;
            z-index: 9;

            svg {
              width: 12px;
              height: auto;
              margin: auto;
            }

            &.slick-next {
              right: 5px;
            }

            &.slick-prev {
              left: 5px;

              svg {
                left: 2px;
                position: relative;
              }
            }

            &::before {
              display: none;
            }

            &:hover {
              color: #000;
            }
          }
        }
      }

      .sliderRightsec {
        position: relative;
        height: 100%;

        h2 {
          font-weight: bold;
          font-size: 30px;
          font-family: "Cormorant Garamond", serif;
          line-height: 36px;
          text-transform: uppercase;
          color: #5d5c61;
          padding-bottom: 15px;
          border-bottom: 1px solid #ededed;
          margin-bottom: 15px;
        }

        .showroomdetail {
          li {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 13px;
            line-height: 36px;
            font-family: "futurabook";
            color: #5d5c61;

            .icon {
              margin-right: 12px;
              display: inline-block;
              color: #5d5c61;

              svg,
              img {
                width: 18px;
                height: 18px;
                object-fit: contain;
              }
            }

            .detailHeading {
              display: inline-block;
              width: 95px;
              font-size: 13px;
              line-height: 36px;
              font-family: "futuramedium";
              color: #000000;
              margin-right: 20px;
            }

            .bedbath {
              display: flex;
              flex-wrap: wrap;
              align-items: center;

              li {
                margin-right: 20px;

                svg {
                  margin-right: 8px;
                  width: 20px;
                  height: 20px;
                }

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }

        .changedesignbtn {
          background: #dff2ff;
          border-color: #dff2ff;
          color: #31719c;
          padding: 11px 30px;
          border-radius: 30px;
          font-size: 14px;
          line-height: 19px;
          // position: absolute;
          // bottom: 0;
          // right: 0;
          display: table;
          margin-left: auto;

          @media (max-width: 991px) {
            position: static;
            margin-top: 20px;
            margin-left: 0;
          }

          &:hover {
            background: #31719c;
            border-color: #31719c;
            color: #fff;
          }
        }
      }

      .imgGal {
        ul {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -5px;
          margin-bottom: -10px;

          li {
            flex-basis: 25%;
            max-width: 25%;
            padding: 0 5px;
            margin-bottom: 10px;

            img {
              width: 100%;
              object-fit: cover;
              border-radius: 8px;

              @media (max-width: 991px) {
                height: 100px;
              }

              @media (max-width: 479px) {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }

  .priceTime {
    padding: 30px 20px;
    background: #f7f7f7;
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    min-height: 384px;

    @media (max-width: 991px) {
      margin-top: 40px;
      min-height: inherit;
    }

    .startingat {
      padding-bottom: 1px;
      margin-bottom: 30px;

      h3 {
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 0;
        font-family: "futuramedium";
        // text-transform: uppercase;
      }
    }

    .startingat-const {
      padding-bottom: 1px;
      margin-bottom: 20px;

      h3 {
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        font-family: "futuramedium";
        margin-bottom: 0;
      }
    }

    .pricetag {
      padding-bottom: 25px;
      margin-bottom: 15px;

      h2 {
        font-size: 24px;
        line-height: 32px;
        color: #000000;
        font-family: "futuramedium";
        margin-bottom: 0;
      }
    }

    .tableWrap {
      border-bottom: 1px solid #e2e2e2;
      margin-bottom: 15px;
      padding-bottom: 15px;

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          td {
            font-size: 18px;
            line-height: 24px;
            font-family: "futuramedium";
            color: #4f4f4f;
            padding-bottom: 30px;

            @media (max-width: 479px) {
              font-size: 14px;
              line-height: 18px;
              padding-bottom: 15px;
            }

            &:last-child {
              text-align: right;
            }
          }

          &:last-child {
            td {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .dateTime {
      ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        li {
          flex-basis: 50%;
          max-width: 50%;
          padding: 0 3px;

          @media (max-width: 479px) {
            flex-basis: 100%;
            max-width: 100%;
          }

          label {
            color: #2f2f2f;
            font-family: "futuramedium";
            font-size: 18px;
            line-height: 24px;
          }

          .form-group {
            position: relative;

            p {
              color: #232121;
              font-size: 14px;
              line-height: 19px;
              font-family: "futuramedium";
            }

            button {
              position: absolute;
              top: 12px;
              right: 10px;
              color: #424242;
              padding: 0;
            }
          }

          input {
            background: #ffffff;
            border-radius: 12px;
            color: #232121;
            font-size: 14px;
            line-height: 19px;
            padding: 8px 10px;
            padding-right: 0px;
            border-color: #fff;
            width: 100%;

            &::placeholder {
              color: #232121;
              opacity: 1;
            }
          }

          &.choosedate {
            .MuiInput-root {
              background: #fff;
              border-top-right-radius: 12px;
              border-bottom-right-radius: 12px;
              border-radius: 12px;
              border-color: #fff;
              width: 100%;
              height: 50px;
              border: 0 !important;
            }

            input {
              width: 100%;
              height: 34px;
            }

            .MuiInput-underline:after,
            .MuiInput-underline:before {
              display: none;
            }
          }
        }
      }

      &.stepField {
        ul {
          li {
            label {
              color: #969696;
            }
          }
        }
      }
    }
  }

  .msgbox {
    background: #ffffff;
    border-radius: 12px;
    background: #fff;
    padding: 22px 15px;
    color: #969696;
    font-family: "futurabook";
    margin-top: 20px;

    h4 {
      font-size: 18px;
      line-height: 24px;
      font-family: "futuramedium";

      color: #31719c;
    }

    textarea {
      color: #969696;
      font-family: "futurabook";
      font-size: 15px;
      line-height: 22px;
      padding: 0;
      background: transparent;
      border: 0;
      height: 80px;
      border-radius: 0;
    }
  }

  &.selectaddPrice {
    .designDetail {
      .lftimg {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100px;
          object-fit: cover;
          border-radius: 8px 0px 0px 8px;
          height: 100%;
        }
      }

      .sliderRightsec {
        @media (max-width: 991px) {
          margin-top: 20px;
        }

        h2 {
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-transform: uppercase;
          color: #31719c;
          padding-bottom: 0;
          border-bottom: 0;
          margin-bottom: 10px;
        }

        .showroomdetail {
          > ul {
            > li {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.addLocation {
  .modal-content {
    background: #ffffff;
    box-shadow: 0px 14px 104px rgba(0, 0, 0, 0.17);
    border-radius: 12px;
  }

  .modal-body {
    padding: 35px 25px;

    .form-group {
      margin-bottom: 30px;

      label {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        font-family: "futuramedium";
        color: #000000;
        margin-bottom: 16px;
      }

      input {
        background: #ffffff url(../../images/gps2.svg) no-repeat left 20px
          center;
        border: 1px solid #e4e4e4;
        border-radius: 6px;
        font-family: "futuramedium";
        color: #afafaf;
        padding-left: 50px;

        &::placeholder {
          color: #afafaf;
          opacity: 1;
        }
      }
    }

    .btngrp {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .btn {
        background: #00709a;
        border-color: #00709a;
        color: #fff;
        border-radius: 30px;
        font-family: "futuramedium";
        margin-right: 15px;
        width: 167px;
        padding: 12px 5px;
        
        &:hover {
          background: transparent;
          color: #00709a;
        }
      }

      .cancelbtn {
        color: #938e94;
        font-family: "futuramedium";
        padding: 0;

        &:hover {
          color: #000;
        }
      }
    }
  }
}

.addressload {
  background: #f6f6f6;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 14px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 17px;

  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    font-family: "futuramedium";
    color: #000000;
    margin-bottom: 0;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    font-family: "futuramedium";
    color: #626262;
    margin-left: auto;

    svg {
      color: #31719c;
      margin-right: 6px;
      width: 20px;
      height: 20px;
      position: relative;
      top: -3px;
    }
  }
}

.accordionsec {
  margin-top: 20px;

  .accordion-item {
    background: #ffffff;
    box-shadow: none;
    // border-radius: 30px !important;
    margin-bottom: 12px;
    border: 0;

    &:last-child {
      margin-bottom: 0;
    }

    .accordion-header {
      margin-bottom: 0;

      > button {
        border: 0;
        box-shadow: none;
        padding: 20px 23px;

        font-size: 18px;
        line-height: 22px;
        color: #4f4f4f;
        background: #e2f3ff !important;
        border-radius: 16px !important;
        font-family: "futuramedium";
        // pointer-events: none;

        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 22px;
          padding: 14px 10px;
        }

        &:after {
          width: 30px;
          height: 30px;
          background: #31719c url(../../images/whdownarr.svg) no-repeat center
            center !important;
          border-radius: 50%;
          color: #fff;
        }

        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }

    .accordion-body {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-top: 0;
      box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 16px 16px;
      padding: 15px 20px;
      padding-left: 30px;

      .steps {
        padding-left: 20px;
        position: relative;

        &:before {
          position: absolute;
          content: "";
          left: 0;
          width: 1px;
          top: 44px;
          height: calc(100% - 82px);
          background: #c0d4e1;
        }

        li {
          position: relative;
          margin-bottom: 20px;
          background: #f3faff;
          border-radius: 10px;
          padding: 18px 20px;

          &:before {
            position: absolute;
            content: "";
            left: -24px;
            width: 10px;
            height: 10px;
            top: 50%;
            transform: -50%;
            background: #31719c;
            border: 1px solid #ffffff;
            border-radius: 50%;
          }

          h4 {
            color: #070933;
            font-family: "futuramedium";
            margin-bottom: 13px;
          }

          p {
            font-size: 12px;
            line-height: 14px;
            font-family: "futurabook";
            color: #5d5c61;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            svg {
              color: #31719c;
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 24px;
      }
    }
  }
}

.Instruction {
  width: 50%;
  margin-top: 20px;
  margin-left: 15px;
  font-size: 20px;
  padding: 30px 20px;
  background: #f7f7f7;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  min-height: 384px;
}

.accordionsec2 {
  // margin-top: 20px;
  width: 50%;
  margin: 20px auto 0;
  @media(max-width: 991px){
    width: 100%;
  }

  .accordion-item {
    background: #ffffff;
    box-shadow: none;
    // border-radius: 30px !important;
    margin-bottom: 12px;
    border: 0;

    &:last-child {
      margin-bottom: 0;
    }

    .accordion-header {
      margin-bottom: 0;

      > button {
        border: 0;
        box-shadow: none;
        padding: 6px 23px;

        font-size: 18px;
        line-height: 22px;
        color: #4f4f4f;
        background: #e2f3ff !important;
        border-radius: 10px !important;
        font-family: "futuramedium";
        // pointer-events: none;

        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 22px;
          padding: 14px 10px;
        }

        &:after {
          width: 30px;
          height: 30px;
          background: #31719c url(../../images/whdownarr.svg) no-repeat center
            center !important;
          border-radius: 50%;
          color: #fff;
        }

        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }

    .accordion-body {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-top: 0;
      box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.04);
      border-radius: 0px 0px 16px 16px;
      padding: 15px 20px;
      padding-left: 30px;

      .steps {
        padding-left: 20px;
        position: relative;

        &:before {
          position: absolute;
          content: "";
          left: 0;
          width: 1px;
          top: 44px;
          height: calc(100% - 82px);
          background: #c0d4e1;
        }

        li {
          position: relative;
          margin-bottom: 20px;
          background: #f3faff;
          border-radius: 10px;
          padding: 18px 20px;

          &:before {
            position: absolute;
            content: "";
            left: -24px;
            width: 10px;
            height: 10px;
            top: 50%;
            transform: -50%;
            background: #31719c;
            border: 1px solid #ffffff;
            border-radius: 50%;
          }

          h4 {
            color: #070933;
            font-family: "futuramedium";
            margin-bottom: 13px;
          }

          p {
            font-size: 12px;
            line-height: 14px;
            font-family: "futurabook";
            color: #5d5c61;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            svg {
              color: #31719c;
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 24px;
        padding: 10px 15px;
        padding-left: 15px;
      }
    }
  }
}

.accordionsec .accordion-item .accordion-header button[aria-expanded="true"] {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.step3 {
  background: #ffffff;
  box-shadow: 0px 24px 154px rgba(112, 112, 112, 0.15);
  padding: 60px 40px;

  @media (max-width: 767px) {
    padding: 30px 14px;
  }

  .logosec {
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    img {
      width: 250px;

      @media (max-width: 767px) {
        width: 180px;
      }
    }
  }

  .step3cont {
    position: relative;

    .bglogo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    ul {
      position: relative;
      z-index: 1;

      li {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 24px;
        color: #000000;

        h4 {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          text-transform: capitalize;
          font-family: "Open Sans", sans-serif;
          color: #000000;
        }
      }
    }
  }

  .signaturesec {
    position: relative;
    padding-left: 12.5%;
    padding-top: 30px;
    font-size: 14px;
    line-height: 24px;
    color: #000000;

    ul {
      max-width: 600px;
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 30px;
        width: 34%;

        &:last-child {
          margin-right: 0;
          width: 20%;
        }
      }

      input {
        border: 0;
        border-bottom: 1px solid #000;
        border-radius: 0;
        background: transparent;
        color: #000;
        padding: 0;
        margin-bottom: 10px;
        padding-bottom: 6px;
      }

      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
        font-family: "Open Sans", sans-serif;
        color: #000000;
      }
    }
  }
}

.step4 {
  .accordionsec2 {
    .accordion {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            @media (max-width: 479px) {
              flex-wrap: wrap;
              padding-right: 44px !important;
            }

            h3 {
              margin-bottom: 0;
              font-size: 16px;
              line-height: 21px;
              font-family: "futuramedium";
              color: #5d5c61;

              @media (max-width: 479px) {
                flex-basis: 100%;
                max-width: 100%;
                margin-bottom: 10px;
              }

              .pricetag {
                margin-left: 20px;
                font-size: 16px;
                line-height: 21px;
                text-transform: uppercase;
                font-family: "futurabold";
                color: #31719c;
              }
            }

            .btndue {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-end;
              margin-left: auto;

              @media (max-width: 479px) {
                margin-left: 0;
              }

              .btn {
                color: #ffffff;
                font-family: "Cormorant Garamond", serif;
                font-weight: 700;
                background: #31719c;
                border-radius: 50px;
                border-color: #31719c;
                padding: 6px 30px;
                line-height: 1.1;
                &:hover {
                  background: transparent;
                  color: #31719c;
                }
              }

              h4 {
                color: #31719c;
                font-family: "Cormorant Garamond", serif;
                font-weight: 700;
                margin: 0 20px;
              }
            }

            &:after {
              margin-left: 0;

              @media (max-width: 479px) {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

        .accordion-body {
          // padding-top: 30px;

          h2 {
            font-size: 18px;
            line-height: 24px;
            color: #070933;
            font-family: "futuramedium";
            padding-bottom: 23px;
            margin-bottom: 23px;
            border-bottom: 1px solid #e0e0e0;
          }
        }
      }
    }
  }
}

.paymentmodal {
  .modal-dialog {
    max-width: 1000px;
    // box-shadow: 0px 10px 55px rgba(36, 36, 36, 0.43);
    border-radius: 0;

    @media (max-width: 991px) {
      max-width: 95%;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 10px;
  }

  .modal-header {
    border-radius: 0;
    border-color: #e6e6e6;
    padding: 15px 5px;
    text-align: center;
    justify-content: center;

    h2 {
      font-size: 36px;
      line-height: 48px;
      text-align: center;
      text-transform: capitalize;
      font-family: "futuramedium";
      color: #000000;
      margin-bottom: 0;
    }
  }

  .modal-body {
    padding: 30px 20px;

    h3 {
      font-size: 20px;
      line-height: 25px;
      text-transform: capitalize;
      font-family: "futuramedium";
      color: #555555;
      margin-bottom: 25px;
    }

    .stripetag {
      background: #ffffff;
      border: 2px solid #c9ebf8;
      box-shadow: 0px 4px 14px #ededed;
      border-radius: 10px;
      padding: 13px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      svg {
        color: #04da7e;
        width: 30px;
        height: auto;
      }
    }

    p {
      font-size: 14px;
      line-height: 16px;
      color: #626262;
    }

    .btn {
      border-radius: 50px;
      background: #00709a;
      color: #fff;

      &:hover {
        background: transparent;
        color: #00709a;
      }
    }
  }

  .formInner {
    @media (max-width: 991px) {
      .row {
        margin-bottom: -20px;

        > div {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.MuiPickersToolbar-toolbar {
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #31719c !important;
}

.MuiPickersDay-daySelected {
  color: #fff;
  font-weight: 500;
  background-color: #31719c !important;
}

.MuiButton-textPrimary {
  color: #31719c !important;
}

.input-time {
  height: 50px !important;
  padding: 0px 10px !important;
  padding-right: 0px !important;

  padding-left: 0px !important;
}

.ant-picker-input {
  input {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.time-new fieldset {
  border: 0px !important;
}

.time-new > div {
  width: 45% !important;
}

.time-new > div input {
  padding: 0px !important;
}

.time-new > div:first-child button {
  width: 77px !important;
  opacity: 0;
  left: 10px !important;
}

.time-new div:nth-child(2) button {
  width: 110px;
  /* border: 1px solid; */
  justify-content: end !important;
}

.minius-text {
  position: absolute;
  left: 35%;
  top: 13px;

  font-weight: bold;
  font-size: 20px;
}

.media-border {
  height: 80px;
  width: 100%;
  border-style: solid;
  border-width: 5px;
  border-color: coral;
}

.media-non {
  border: none;
}

.nxtbtn {
  margin-top: 20px;
  text-align: right;

  .btn {
    background: #dff2ff;
    border-color: #dff2ff;
    color: #31719c;
    border-radius: 30px;
    min-width: 150px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    &.seduleBtn_main{
      font-size: 14px;
      padding: 11px 30px;
      line-height: 19px;
    }
    &:hover {
      background: #31719c;
      border-color: #31719c;
      color: #fff;
    }
  }
}

.timedrop {
  .dropdown-toggle {
    background: #ffffff url(../../images/clockic1.svg) no-repeat right 10px
      center !important;
    border-radius: 12px;
    color: #232121;
    font-size: 14px;
    line-height: 19px;
    padding: 8px 10px;
    padding-right: 40px;
    border-color: #fff;

    height: 50px;
    padding-right: 50px;
    border: 0 !important;

    &:focus {
      box-shadow: none;
      border: 0;
      color: #232121;
    }

    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
}

.MuiPickersDay-current {
  font-weight: 600;
}

.MuiTypography-colorPrimary {
  color: #31719c !important;
}

.MuiFormHelperText-root.Mui-error {
  color: #eee8e7 !important;
}

.project-overview-txt {
  padding: 20px 0px 0px 20px;
  font-size: 18px;

  font-family: "futuramedium";
  color: #000;
}

.dropdown-list {
  width: 100%;
  font-size: 15px;
}

.btnwh {
  // position: absolute;
  // z-index: 1;
  // bottom: -7px;
  // right: 149px;
  background: #31719c;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-family: "futuramedium";
  text-align: center;
  // color: #31719c;
  padding: 8px 18px;
  border-radius: 30px;
  border-color: #31719c;

  &:hover {
    background: #fff;
    color: #31719c;
  }
}

.otherBtnwh {
  position: absolute;
  z-index: 1;
  bottom: -7px;
  right: 149px;
  background: #31719c;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-family: "futuramedium";
  text-align: center;
  // color: #31719c;
  padding: 8px 18px;
  border-radius: 30px;
  border-color: #31719c;

  &:hover {
    background: #fff;
    color: #31719c;
  }
}

.closeBtn {
  position: absolute;
  border-radius: 5px;
  right: 5px;
  top: 5px;
  background: rgb(234, 230, 230);
  font-size: 16px;
  font-weight: bold;
  color: #ca3e3e;

  &:hover {
    background: rgb(165, 160, 160);
    color: #000;
  }
}

.imgCls {
  height: 300px;
  border-radius: 5px;
  z-index: 1;
}

.loader-class {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.slec2 {
  .dropdown-item {
    position: relative;
    padding-right: 40px;
    button {
      position: absolute;
      right: 10px;
      color: #31719c;
      font-weight: 800;
      font-size: 17px;
    }
  }
}

.disabled{
  pointer-events: none!important;
}

.wrapper_stepFour{
  @media(max-width: 991px){
    flex-wrap: wrap;
  }
}

