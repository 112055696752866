.showroomban {
  position: relative;

  figure {
    margin-bottom: 0;

    img {
      width: 100%;

      @media (max-width: 479px) {
        height: 150px;
        object-fit: cover;
      }
    }
  }

  .bancont {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;

    h1 {
      font-size: 64px;
      line-height: 85px;
      /* identical to box height */
      font-family: "futuramedium";
      text-align: center;
      text-transform: capitalize;

      color: #ffffff;

      @media (max-width: 991px) {
        font-size: 50px;
        line-height: 55px;
      }

      @media (max-width: 479px) {
        font-size: 35px;
        line-height: 40px;
      }
    }
  }
}

.showroomItems {
  padding: 43px 0 100px 0;

  .selectfilter {
    margin-bottom: 43px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 15px;

        @media (max-width: 479px) {
          margin-right: 0;
          margin-bottom: 10px;
          flex-basis: 100%;
          max-width: 100%;
        }

        &:last-child {
          margin-right: 0;
        }

        select {
          background: #ffffff url(../../images/down-arrow.svg) no-repeat right
            10px center;
          border: 1px solid #dfdfdf;
          box-shadow: 0px 4px 16px rgba(106, 146, 173, 0.33);
          border-radius: 50px;
          font-size: 14px;
          line-height: 19px;
          text-transform: uppercase;
          font-family: "futuramedium";
          color: #000000;
          min-width: 196px;
        }
      }
    }
  }

  .row {
    margin-bottom: -30px;

    > div {
      margin-bottom: 30px;
    }

    .item {
      border-radius: 16px;
      overflow: hidden;
      background: #ffffff;
      border: 1px solid #dedede;
      transition: 0.3s all ease-in-out 0s;
      height: 100%;
      .text-wrap{
        display: block;
        max-width: 85%;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis;
        @media(max-width: 1399px){
        max-width: 85%;
        }
        @media(max-width: 1199px){
          max-width: 80%;
          }
      }
      &:hover {
        box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.17);
      }

      figure {
        margin-bottom: 0;

        img {
          height: 260px;
          object-fit: cover;
          width: 100%;

          @media (max-width: 1199px) {
            height: 200px;
          }
        }
      }

      .itemInner {
        padding: 25px 15px;

        .nameBtn {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          > div{
            display: flex;
            width: 100%;
          }
          h3{
            display: flex;
            align-items: center;
            span{
              margin: 0 5px;
            }
          }
          .smHead{
            font-size: 16px;
          }
          h6{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            color: #626262;
            font-family: "Cormorant Garamond", serif;
            margin-bottom: 0;
          }
          

          .btnwh {
            font-size: 12px;
            line-height: 16px;
            font-family: "futuramedium";
            text-align: center;
            color: #fff;
            padding: 8px 18px;
            border-radius: 30px;
            border-color: #31719c;

            &:hover {
              background: #fff;
              color: #31719c;
            }
          }
        }

        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          text-transform: uppercase;
          color: #626262;
          font-family: "Cormorant Garamond", serif;
          margin-bottom: 0;
        }

        h6{
          font-family: "Cormorant Garamond", serif;
          color: #626262;
          font-size: 16px;
          font-weight: 600;
        }
        .bedPrice {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          h4 {
            font-size: 14px;
            line-height: 16px;
            font-family: "futurabook";
            color: #5d5c61;
            margin-bottom: 0;
          }

          .pricetag {
            color: #31719c;
            font-size: 14px;
            line-height: 19px;
            color: #31719c;
            font-family: "futuramedium";
            display: flex;
            align-items: center;

            svg {
              width: 17px;
              height: 17px;
              margin-right: 5px;
              position: relative;
              top: -1px;
            }
          }
        }
      }
    }
  }
}

.showroomdetailmodal {
  .modal-dialog {
    max-width: 1000px;

    @media (max-width: 991px) {
      max-width: 95%;
    }
  }

  .modal-content {
    border-radius: 20px;

    .modal-header {
      justify-content: flex-end;
      border-bottom: 0;

      .closebtn {
        padding: 0;
      }
    }

    .modal-body {
      padding: 20px 25px 50px 25px;

      @media (max-width: 479px) {
        padding: 20px 15px 20px 15px;
      }

      .row {
        .lftimgslider {
          .slick-slider {
            img {
              border-radius: 12px;
              width: 100%;

              @media (max-width: 991px) {
                height: 300px;
                object-fit: cover;
              }

              @media (max-width: 479px) {
                height: 200px;
              }
            }

            .slick-arrow {
              background: #ffffff;
              box-shadow: 0px 4px 44px #ffdbe1;
              border-radius: 50%;
              width: 40px;
              height: 40px;
              display: flex !important;
              align-self: center;
              justify-content: center;
              color: #000000;
              z-index: 9;
              background-position: center;
              background-repeat: no-repeat;

              &.slick-next {
                right: 10px;
                background-image: url("arrow_forward_ios_black_24dp.svg");
              }

              &.slick-prev {
                left: 10px;

                background-image: url("arrow_back_ios_black_24dp.svg");
              }

              &::before {
                display: none;
              }

              &:hover {
                color: #31719c;
              }
            }
          }
        }

        .sliderRightsec {
          @media (max-width: 991px) {
            padding-top: 30px;
          }

          h2 {
            font-weight: bold;
            font-size: 36px;
            font-family: "Cormorant Garamond", serif;
            line-height: 44px;
            text-transform: uppercase;
            color: #5d5c61;
            padding-bottom: 15px;
            border-bottom: 1px solid #ededed;
            margin-bottom: 15px;

            @media (max-width: 479px) {
              font-size: 25px;
              line-height: 32px;
            }
          }

          .showroomdetail {
            padding-bottom: 15px;
            border-bottom: 1px solid #ededed;
            margin-bottom: 15px;

            li {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              font-size: 14px;
              line-height: 36px;
              font-family: "futurabook";
              color: #5d5c61;

              .icon {
                margin-right: 12px;
                display: inline-block;
                color: #5d5c61;

                svg,
                img {
                  width: 18px;
                  height: 18px;
                  object-fit: contain;
                }
              }

              .detailHeading {
                display: inline-block;
                width: 110px;
                font-size: 14px;
                line-height: 36px;
                font-family: "futuramedium";
                color: #000000;
                margin-right: 20px;
              }

              .bedbath {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                li {
                  margin-right: 20px;

                  svg {
                    margin-right: 8px;
                    width: 20px;
                    height: 20px;
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }

        .imgGal {
          ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
            margin-bottom: -20px;

            li {
              flex-basis: 25%;
              max-width: 25%;
              padding: 0 10px;
              margin-bottom: 20px;

              @media (max-width: 479px) {
                flex-basis: 50%;
                max-width: 50%;
              }

              img {
                width: 100%;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.loaderwrapper {
  text-align: center;

  img {
    width: 100px;
  }
}
.media-thumble {
  height: 80px;
  // width: 100px;
  display: block;
  img {
    margin: auto;
    display: block;
    width: 80%;
    height: 80px;
    object-fit: contain;
  }
}
.media-thumble1 {
  height: 80px;
  border: solid 2px red;
  display: block;
  img {
    margin: auto;
    display: block;
    width: 80%;
    height: 80px;
    object-fit: contain;
  }
}

.mui_select {
  .MuiOutlinedInput-root {
    // border-radius: 32px;
    // line-height: normal;
    font-family: "futuramedium";
    background: transparent;
  }
  .MuiFormControl-root{
    background: #ffffff url(../../images/down-arrow.svg) no-repeat right 10px
    center;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 4px 16px rgba(106, 146, 173, 0.33);
  border-radius: 50px;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  font-family: "futuramedium";
  color: #000000;
  min-width: 196px;
  }
  .MuiSelect-select,.Mui-focused {

  }



  .MuiSvgIcon-root {
    display: none;
  }
  .MuiOutlinedInput-notchedOutline {
    top: 0;
    border: none !important;
  }
}
