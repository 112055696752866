@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
.banerimg{
  position: relative;
  figure{
    margin-bottom: 0px;
  }
  img{
 width:100%;
 height: 705px;
 object-fit: cover;
 @media (max-width:767px){
  height: 450px;
 }
 @media (max-width:480px){
  height: 350px;
 }
  }
  &:after{
    background: rgba(9, 9, 9, 0.25);
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .banertext{
    position: absolute;
    width: 100%;
    margin: 0px auto;
    top: 130px;
    left: 0px;
    z-index: 1;
    @media(max-width:991px){
      top: 50px;
    }
    h2{
      font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 1.2;
text-align: left;
text-transform: uppercase;
color: #FFFFFF;
margin-bottom: 15px;
    }
    h1{
      font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 1.3;
text-align: left;
color: #FFFFFF;
margin-bottom: 15px;
@media(max-width:1024px){
  font-size: 44px;
}
@media(max-width:768px){
  font-size: 24px;
}
    }
    p{
      font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 500;
font-size: 19px;
line-height: 1.3;
color: #FFFFFF;
max-width: 357px;
width: 357px;
@media (max-width:480px){
  max-width: 100%;
width: 100%;
font-size: 14px;
line-height: 1.3;
}
    }
    .choose-design{
      background: #0068B4;
border-radius: 47px;
font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 1.3;
text-align: center;
color: #FFFFFF;
padding: 20px 25px;
@media(max-width:768px){
  font-size: 12px;
  padding: 10px 15px;
}
&:hover{
  background: #535353;
}
    }
  }
}
.whatmakes{
  background:rgba(235, 245, 251, 0.4);
  padding-top: 109px;
  padding-bottom: 105px;
  @media(max-width:767px){
    padding-top: 50px;
    padding-bottom: 55px;
  }
  &.background-none{ background: inherit; padding: 62px 0 94px 0;
    p{
      max-width: 787px;
    }
  }
  h2{
    font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 1.3;
color: #535353;
margin-bottom: 20px;
text-align: center;
@media(max-width:1024px){
  font-size: 40px;
}
@media(max-width:768px){
  font-size: 24px;
}
  }
  p{
    font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #535353;
  text-decoration: none;
  text-align: center;
  max-width: 995px;
  margin: 0px auto;
  }
  .what-makes-con{
    text-align: center;
    @media (max-width:767px){
      margin-bottom: 25px;
    }
    figure{
      height: 100px;
      display: flex;
    justify-content: center;

    }
    h4{
      font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 26px;
color: #535353;
width: 225px;
margin: 0px auto;
    }
  }
  .containsection{
    padding-top: 78px;
    .what-makes-con1{
      max-width: 302px;
      margin: 0px auto;
      background: #FFFFFF;
border: 1px solid #E2E2E2;
box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
border-radius: 8px;
position: relative;
padding:70px 0 32px 0;
@media (max-width:576px){
  margin-bottom: 20px;
}
span{
  position: absolute;
  left: 0px;
  top: 0px;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
width: 67px;
height: 67px;
text-align: center;
font-style: normal;
font-weight: 400;
font-size: 26px;
line-height: 1.2;
text-align: center;
color: #477288;
background:rgba(57, 138, 179, 0.6);
padding-right: 25px;
padding-top: 5px;
}
      figure{
        height:100px;
        margin: 0px auto;
        text-align: center;
      }
    h4{
      font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 1.2;
text-align: center;
color: #535353;
margin-bottom: 20px;
    }
    p{
      font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 300;
font-size: 19px;
line-height: 1.3;
text-align: center;
color: #535353;
max-width: 225px;
width:225px;
margin: 0px auto;
min-height: 130px;
    }
  }
  }
}
.staty-connect{
  background:rgba(235, 245, 251, 0.4);
  padding-top: 60px;
  padding-bottom: 70px;
  @media (max-width:767px){
    padding-top: 30px;
    padding-bottom: 35px;
  }
  .stay-connect-main{
    display: flex;
    align-items: center;
    @media (max-width:576px){
      .col-sm-4{
        text-align: center;
      }
    }
  }
  p{
    font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 500;
font-size: 19px;
line-height: 1.2;
color: #535353;
max-width: 625px;
margin-bottom: 25px;
@media (max-width:767px){
  font-size: 15px;
}
  }
  h2{
    font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 1.5;
color: #535353;
max-width: 634px;
@media(max-width:1024px){
  font-size: 40px;
}
@media(max-width:768px){
  font-size: 24px;
}
  }
  ul{
    list-style: none;
    li{
      background: url(../../asset/stay-icon1.svg) no-repeat left center;
      padding-left: 35px;
      margin-bottom: 32px;
      font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 1.5;
color: #535353;
@media (max-width:991px){
  font-size: 15px;
  background-position: left top 2px;
  background-size: 20px 20px;
}
    }
  }
}
.testimonials{
  padding: 108px 0;
  @media(max-width:1024px){
    padding: 58px 0;
  }
  h2{
    font-family: 'IBM Plex Sans';
font-weight: 700;
font-size: 48px;
line-height: 1.4;
color: #535353;
text-align: center;
margin-bottom: 50px;
@media(max-width:1024px){
  font-size: 40px;
}
@media(max-width:768px){
  font-size: 24px;
}
  }
  
    .sildermain{

&:hover{
  box-shadow: 0px 0px 15px 12px rgba(0, 0, 0, 0.08);
}
&:focus{
  box-shadow: 0px 0px 15px 12px rgba(0, 0, 0, 0.08);
}
  }

.slick-dots li button:before{
  font-size: 10px; color: rgba(0, 104, 180, 0.6);
}
.slick-dots li.slick-active button:before{
  color:rgba(0, 104, 180, 1);
}
.slick-dots li{
  margin: 0px;
  padding: 0px;
}
  .sildermain{
    padding: 35px 40px;
    background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
border-radius: 6px;
margin: 20px;

    p{
      font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 300;
font-size: 15px;
line-height: 20px;
text-align: center;
color: #11243E;
background: url(../../asset/q-icon1.svg) no-repeat left top,url(../../asset/q-icon2.svg) no-repeat right bottom;


  padding:25px 20px;
  span{
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
    }
    .silder-mid{
      max-width: 222px;
      margin: 0px auto;
      display: flex;
      align-items: center;
      figure{
        width: 73px;
        height: 73px;
        border-radius: 50%;
        margin-right: 15px;
        margin-bottom: 0px;
      }
      div{
        width: calc(100% - 90px);
      h4{
        font-family: 'Neue Haas Unica W1G';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #535353;  
        text-decoration: none;  
        margin-bottom: 0px;    
      }
      h5{
        font-family: 'Neue Haas Unica W1G';
font-style: normal;
font-weight: 350;
font-size: 14px;
line-height: 21px;
color: #11243E;
      }
    }
    }
  }
}
.testimonials-silder{
  @media (max-width:991px){
  padding-left: 20px;
  padding-right: 20px;
}
}
.stay-connect-img{
  padding-left: 12%;
  @media (max-width:1024px){
    padding-left: 20px;
  } 
  @media (max-width:767px){
    padding-left:0px;
  } 
}