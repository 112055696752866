.hmban {
  position: relative;

  figure {
    margin-bottom: 0;

    img {
      width: 100%;
      height: 87vh;
      object-fit: cover;

      @media (max-width: 991px) {
        height: inherit;
      }

      @media (max-width: 767px) {
        height: 300px;
        object-fit: cover;
      }
    }
  }

  .bannerCont {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(-65%);

    .innerHmBan {
      max-width: 523px;
      color: #ffffff;

      h1 {
        color: #ffffff;
        font-family: "Cormorant Garamond", serif;
        font-weight: bold;
        font-size: 55px;
        line-height: 64px;

        @media (max-width: 991px) {
          font-size: 45px;
          line-height: 50px;
        }

        @media (max-width: 767px) {
          font-size: 30px;
          line-height: 34px;
        }
      }

      .bannerbtn {
        color: #557a95;
        background: #ffffff;
        border: 1px solid #557a95;
        position: relative;
        display: inline-block;
        transition: 0.3s all ease-in-out 0s;

        &:hover {
          background: #557a95;
          color: #fff;
        }
      }
    }
  }
}

.bannerbottom {
  .item {
    background: #ffffff;
    box-shadow: 0px 0px 74px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 18px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: 1199px) {
      padding: 15px 10px;
    }

    @media (max-width: 991px) {
      margin-bottom: 20px;
    }

    h3 {
      font-size: 14px;
      line-height: 22px;
      font-family: "futuramedium";
      color: #5d5c61;
      margin-bottom: 0;
      padding-left: 28px;
      position: relative;

      @media (max-width: 1199px) {
        font-size: 12px;
        line-height: 18px;
        padding-left: 23px;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        object-fit: contain;

        @media (max-width: 1199px) {
          width: 17px;
          height: 17px;
        }
      }
    }
  }

  .row {
    align-items: center;
  }
}

.hmBestAff {
  position: relative;

  .row {
    align-items: center;

    @media (max-width: 991px) {
      margin-bottom: -30px;
    }

    >div {
      @media (max-width: 991px) {
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }

  &.gapsec {
    padding-top: 0;
  }

  .hmBestAfflogo {
    position: absolute;
    left: 14px;
    bottom: 8%;
    height: 520px;
    object-fit: contain;
    width: 90px;
  }

  figure {
    margin-bottom: 0;

    img {
      @media (max-width: 991px) {
        width: 60%;
      }
    }
  }
}

.hmHowWork {
  background: #608ca2;
  text-align: center;
  padding: 50px 0;

  .innerhmHowWork {
    max-width: 832px;
    margin: 0 auto;
    color: #fff;

    h2 {
      color: #fff;
    }

    .downarrow {
      border: 2px solid #a0c0d7;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      color: #fff;
      border-radius: 50%;

      svg {
        width: auto;
        height: 32px;
      }

      &:hover {
        background: #a0c0d7;
      }
    }
  }
}

.hmExpandPanel {
  .row {
    align-items: center;
  }

  ul {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #e3e3e3;
    overflow: hidden;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      border-bottom: 0;
    }

    * {
      transition: ease 0.8s;
    }

    li {
      // flex-basis: 16.666%;
      // max-width: 16.666%;
      border-right: 1px solid #e3e3e3;
      padding: 38px 80px;
      min-height: 300px;
      background: #fff;
      flex: 1;
      cursor: pointer;

      @media (max-width: 1199px) {
        padding: 25px 40px;
      }

      @media (max-width: 991px) {
        padding: 20px 25px;
      }

      @media (max-width: 767px) {
        flex-basis: 50%;
        max-width: 50%;
        padding: 20px 10px;
        border-bottom: 1px solid #e3e3e3;
        min-height: inherit;
      }

      @media (max-width: 479px) {
        flex-basis: 100%;
        max-width: 100%;
      }

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 50px;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #898388;
        font-family: "futuramedium";
        border: 1px solid #b0b0b0;
        width: 72px;
        height: 72px;
        border-radius: 50%;

        @media (max-width: 1199px) {
          width: 60px;
          height: 60px;
          font-size: 20px;
          line-height: 25px;
        }

        @media (max-width: 991px) {
          margin-bottom: 20px;
          width: 50px;
          height: 50px;
          font-size: 17px;
          line-height: 20px;
        }

        @media (max-width: 767px) {
          margin-bottom: 10px;
          width: 40px;
          height: 40px;
          font-size: 15px;
          line-height: 20px;
        }
      }

      .headingContent {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        h3 {
          font-weight: bold;
          font-size: 48px;
          line-height: 52px;
          text-align: right;
          margin-bottom: 0;
          color: #5d5c61;
          font-family: "Cormorant Garamond", serif;
          transform: rotate(180deg);
          writing-mode: vertical-lr;
          transform-origin: center;
          // transition: initial;

          @media (max-width: 1199px) {
            font-size: 35px;
            line-height: 40px;
          }

          @media (max-width: 479px) {
            writing-mode: inherit;
            transform-origin: inherit;
            transform: inherit;
            font-size: 30px;
            line-height: 35px;
          }
        }

        .content {
          opacity: 0;
          width: 0;
          height: 0;
          // transition: initial;
          transition: none;

          p {
            // transition: initial;
          }
        }
      }

      &.active {
        flex-basis: 50%;
        max-width: 50%;
        background: #e9f6ff;

        @media (max-width: 479px) {
          flex-basis: 100%;
          max-width: 100%;
        }

        .number {
          margin-left: 0;
        }

        .headingContent {
          align-items: flex-start;
          justify-content: flex-start;

          h3 {
            color: #bdcedb;

            @media (max-width: 479px) {
              writing-mode: vertical-lr;
              transform-origin: center;
              transform: rotate(180deg);
            }
          }

          .content {
            flex-basis: calc(100% - 53px);
            max-width: calc(100% - 53px);
            padding-left: 20px;
            color: #5d5c61;
            width: 100%;
            opacity: 1;
            height: auto;
            transition: ease-in 0.5s 0.5s;

            h4 {
              font-weight: bold;
              font-size: 40px;
              line-height: 48px;
              font-family: "Cormorant Garamond", serif;
              color: #2b4257;
              margin-bottom: 13px;

              @media (max-width: 1199px) {
                font-size: 30px;
                line-height: 38px;
              }
            }
          }
        }
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.hmphnsec {
  .row {
    align-items: center;

    @media (max-width: 991px) {
      >div {
        &:first-child {
          text-align: center;
          margin-bottom: 30px;

          img {
            max-height: 400px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .headingsec {
    position: relative;

    .dotsbg {
      position: absolute;
      top: -70px;
      left: -40px;
      z-index: -1;

      @media (max-width: 991px) {
        top: -40px;
        left: -30px;
        z-index: -1;
        width: 130px;
        height: 130px;
        object-fit: contain;
      }
    }
  }

  .listitems {
    margin-bottom: 35px;

    ul {
      li {
        padding-left: 30px;
        background: url(../../images/checktick.svg) no-repeat 0px 4px;
        color: #5d5c61;
        font-weight: 600;
        margin-bottom: 13px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .phvideo_area {
    position: relative;

    .phimg {
      width: 100%;
      height: 484px;
      object-fit: contain;
    }

    video {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 96.4%;
      object-fit: contain;
      z-index: -1;
      top: 7px;
    }
  }
}

.customerTestimonial {
  .headingsec {
    text-align: center;
  }

  .row {
    margin: 0;

    .lftimg {
      padding: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px 0px 0px 12px;
      }
    }

    .testiSliderOuter {
      background: #f9f9f9;
      border-radius: 0px 12px 12px 0px;
    }

    .testimonialSlider {
      text-align: center;
      padding: 28px 80px;

      @media (max-width: 1199px) {
        padding: 20px 30px;
      }

      @media (max-width: 479px) {
        padding: 20px 10px;
      }

      img {
        display: inline-block;
      }

      figure {
        margin-bottom: 15px;

        img {
          border: 5px solid #ffffff;
          width: 144px;
          height: 144px;
          object-fit: cover;
          border-radius: 50%;
          margin: 0 auto;
        }
      }

      h3 {
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        font-family: "futuramedium";
        color: #5d5c61;
      }

      .starsec {
        margin-bottom: 9px;
      }

      .comments {
        padding: 25px 25px;
        max-width: 373px;
        margin: 0 auto;
        position: relative;

        @media (max-width: 991px) {
          max-width: 100%;
        }

        &:after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 40px;
          height: 26px;
          background: url(../../images/quotecomma.svg) no-repeat 0 0;
          background-size: contain;
        }

        &:before {
          position: absolute;
          content: "";
          bottom: 0;
          right: 0;
          width: 40px;
          height: 26px;
          background: url(../../images/quotecomma.svg) no-repeat 0 0;
          background-size: contain;
          transform: rotate(180deg);
        }
      }

      .slick-dots {
        position: static;

        li {
          margin: 0;
          margin-right: 6px;
          width: auto;
          height: auto;

          &:last-child {
            margin-right: 0;
          }

          button {
            padding: 0;
            background: #abaeab;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin: 0;

            &::before {
              display: none;
            }
          }

          &.slick-active {
            button {
              background: #0f100f;
              border-radius: 30px;
              width: 19px;
            }
          }
        }
      }
    }
  }
}

.ourService {
  .row {
    margin-bottom: -20px;

    >div {
      margin-bottom: 20px;
    }
  }

  .item {
    position: relative;
    cursor: pointer;

    figure {
      margin-bottom: 0;
      border-radius: 12px;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        border-radius: 12px;
      }

      .number {
        position: absolute;
        top: 14px;
        right: 14px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        color: #00709a;
        font-family: "futuramedium";
      }
    }

    h3 {
      font-size: 18px;
      line-height: 24px;
      text-transform: capitalize;
      font-family: "futuramedium";
      color: #ffffff;
      margin-bottom: 0;
      position: absolute;
      bottom: 14px;
      left: 14px;
      width: 90%;
    }
  }
}