 .showroomItems-list-project{
     
 
 

.loadmore-class{
    background: #DFF2FF;
    border-color: #DFF2FF;
    color: #31719C;
    border-radius: 30px;
    min-width: 150px;
    height: 50px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin:0px 0px 30px 0px;
}
.loadmore-class:hover{
    background:  #31719C;
    border-color: #DFF2FF;
    color: #DFF2FF;
    border-radius: 30px;
    min-width: 150px;
    height: 50px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin:0px 0px 30px 0px;
}






}



.list-project{
    padding-top: 0;
    padding-bottom: 40px;
}
.loadmore-class{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #557A95;
    font-family: "Cormorant Garamond", serif;
    margin-bottom: 0;
}
.addressbar{
    margin-bottom: 5px;font-size: 14px;
    line-height: 16px;
    font-family: "futurabook";
    color: #5d5c61;
    svg{
        width: 20px;
        position: relative;
        top: -2px;
        height: auto;
    }
}

.deleteModalModification{
    .modal-content{
        width: 284px;
    }
}

.item{
    figure{
        position: relative;
        .delect_icon{
            position: absolute;
            top: 10px;
            right: 10px;
            &:hover{
                p{
                    display: block;
                }
                    
            }
            p{
                display: none;
                background-color: #000;
                color: #fff;
                padding: 5px 10px;
                font-size: 14px;
                position: absolute;
                right: 20px;
                top: -1px;
                width: 120px;

            }
            svg{
                position: initial;
                
            }
        }
    }
    
}
