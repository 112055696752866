.innerLogin {
    background: #ffffff;
    box-shadow: 0px 10px 55px #e9eff3;
    border-radius: 10px;
    padding: 50px 24px;
    max-width: 500px;
    margin: 0 auto;
  
    h2 {
      text-align: center;
      color: #000000;
      margin-bottom: 16px;
    }
  
    p {
      text-align: center;
      color: #626262;
      font-family: "futurabook";
      margin-bottom: 30px;
    }
  
    .ortag {
      margin: 25px 0;
      position: relative;
  
      &::before {
        content: "";
        position: absolute;
        top: 9.5px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #e5e5e5;
      }
  
      span {
        display: table;
        margin: 0 auto;
        padding: 0 15px;
        background: #fff;
        position: relative;
        z-index: 1;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */
  
        text-align: center;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        font-family: "futuramedium";
        color: #5d5c61;
      }
    }
  
    .forgotpassLink {
      text-align: right;
      font-family: "futurabook";
      padding-top: 13px;
  
      a {
        color: #5d5c61;
  
        &:hover {
          color: #00709a;
        }
      }
    }
  
    .loginbtn {
      padding-top: 10px;
  
      button {
        width: 100%;
        border-radius: 50px;
        background: #00709a;
        border: 1px solid #00709a;
        color: #fff;
  
        &:hover {
          background: transparent;
          color: #00709a;
        }
      }
    }
  
    .btnwh {
      width: 100%;
      border-radius: 50px;
    }
  }
  