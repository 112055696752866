// .profileImg img {
//   border-radius: 50%;
//   width: 150px;
//   height: 150px;
//   object-fit: cover;
//   margin-bottom: 10px;
//   margin-left: 140px !important;
//   margin-right: 0px !important;
//   bottom: 5px;
//   right: 50px !important;
// }

.uploadimg {
  margin-bottom: 30px;
}
.uploadimg img {
  margin-bottom: 0;
}
.uploadimg img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 0;
}
.uploadimg button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  background-color: white;
  color: black;

  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  // border: 1px dashed black;
  border: hidden;
  transition: 0.2s;
  cursor: pointer;
}
.uploadimg button {
  padding: 8px 20px;
  font-size: 12px;
  &:hover {
    background-color: black;
    color: white;
    transition: 0.2s;
    // border: 1px dashed white;
  }
}
.innerUploadimg {
  position: relative;
  display: table;
  margin: 0 auto;
  margin-bottom: 30px;
}
