@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: 'futurabold';
  src: url('./fonts/futura_bold-webfont.eot');
  src: url('./fonts/futura_bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura_bold-webfont.woff2') format('woff2'),
    url('./fonts/futura_bold-webfont.woff') format('woff'),
    url('./fonts/futura_bold-webfont.svg#futurabold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'futuramedium';
  src: url('./fonts/futura_medium-webfont.eot');
  src: url('./fonts/futura_medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura_medium-webfont.woff2') format('woff2'),
    url('./fonts/futura_medium-webfont.woff') format('woff'),
    url('./fonts/futura_medium-webfont.svg#futuramedium') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'futuraregular';
  src: url('./fonts/futura_regular-webfont.eot');
  src: url('./fonts/futura_regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura_regular-webfont.woff2') format('woff2'),
    url('./fonts/futura_regular-webfont.woff') format('woff'),
    url('./fonts/futura_regular-webfont.svg#futuraregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'futurabook';
  src: url('./fonts/futura_book_font-webfont.eot');
  src: url('./fonts/futura_book_font-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/futura_book_font-webfont.woff2') format('woff2'),
    url('./fonts/futura_book_font-webfont.woff') format('woff'),
    url('./fonts/futura_book_font-webfont.svg#futurabook') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: "Neue Haas Unica W1G";
  src: url("./fonts/31267e36af6f8dac65a56e78345e945e.eot");
  /* IE9*/
  src: url("./fonts/31267e36af6f8dac65a56e78345e945e.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./fonts/31267e36af6f8dac65a56e78345e945e.woff2") format("woff2"),
    /* chrome、firefox */
    url("./fonts/31267e36af6f8dac65a56e78345e945e.woff") format("woff"),
    /* chrome、firefox */
    url("./fonts/31267e36af6f8dac65a56e78345e945e.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("./fonts/31267e36af6f8dac65a56e78345e945e.svg#Neue Haas Unica W1G") format("svg");
  /* iOS 4.1- */
}

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

// font-family: 'Cormorant Garamond', serif;
// font-family: 'Open Sans', sans-serif;
// font-family: 'futurabold';
// font-family: 'futuramedium';
// font-family: 'futuraregular';
// font-family: 'futurabook';
// font-family: 'Poppins', sans-serif;
body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-size: 17px;
  line-height: 26px;
  color: #938E94;

  @media(max-width:767px) {
    font-size: 15px;
    line-height: 25px;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

a {
  color: #525255;
  text-decoration: none;
}

a:hover {
  color: #31719C;
  text-decoration: none;
}

a:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #5D5C61;
  font-family: 'futurabold';
  font-weight: normal;
  font-style: normal;
  padding: 0;
  margin: 0 0 20px 0;
}

h1 {
  font-size: 43px;
  line-height: 52px;

  @media(max-width:767px) {
    font-size: 32px;
    line-height: 42px;
  }

  @media(max-width:479px) {
    font-size: 26px;
    line-height: 37px;
  }
}

h2 {
  font-size: 35px;
  line-height: 45px;
  // text-transform: capitalize;

  // font-size: 32px;
  // line-height: 42px;

  @media(max-width:1199px) {
    font-size: 27px;
    line-height: 32px;
  }

  @media(max-width:991px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media(max-width:767px) {
    font-size: 20px;
    line-height: 24px;
  }
}

h3 {
  font-size: 18px;
  line-height: 23px;

  @media(max-width:1199px) {
    font-size: 16px;
    line-height: 22px;
  }


}

h4 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;

  @media(max-width:1199px) {
    font-size: 15px;
    line-height: 19px;
  }
}

h5 {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 12px;
}

h6 {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
}

p {
  margin: 0 0 20px 0;
}

p:last-child {
  margin-bottom: 0;

}

ol {
  padding-left: 20px;

}

ol li {
  margin-bottom: 5px;

}

ul {
  padding: 0;

}

ul li {
  padding: 0;
  // padding-left: 20px;
  position: relative;
  list-style: none;
  // margin-bottom: 5px;

}

// ul li:before{
//       content: '';
//     width: 7px;
//     height: 7px;
//     border-radius: 100%;
//     background: #49d4ad;
//     position: absolute;
//     top: 9px;
//     left: 0;

// }
a {
  transition: 0.3s all ease-in-out 0s;
}

.dropdown-toggle:focus {
  outline: none;
}

label {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  font-family: 'futuramedium';
  font-weight: normal;
  font-style: normal;
  color: #626262;
  margin-bottom: 11px;
  display: block;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

input:not([type='checkbox']),
input:not([type='radio']),

textarea,
.form-control,
select,
.mat-select-trigger {
  appearance: none;
  background: #fff;
  border-radius: 30px;
  width: 100%;
  height: 52px;
  padding: 8px 20px;
  font-weight: 500;
  line-height: 18px;
  color: #000;
  border: 1px solid #E5E5E5;
  font-size: 18px;
  line-height: 19px;
  font-family: 'futurabook';

  &:focus {
    border: 1px solid #C9EBF8;
  }

  &::placeholder {
    color: #DADADA;
    opacity: 1;
  }
}

:focus-visible {
  outline: 0;
}

select {
  padding-right: 30px;
  background: url(./images/down-arrow.svg) no-repeat right 10px center #F0F9FF;
  background-size: 15px;
  appearance: none;
  color: #00709A;

  .mat-select-value {
    color: #00709A;
    vertical-align: middle;
  }


  .mat-select-arrow-wrapper {
    opacity: 0;
    padding: 0;
    width: 0;
    height: 0;

    .mat-select-arrow {
      border: 0
    }
  }
}

select[multiple] {
  background: transparent;
  min-height: 85px;
}


.navbar-nav li:before {
  display: none;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}


button {
  background: transparent;
  border: 0;
  transition: 0.3s all ease-in-out 0s;
  cursor: pointer;
}

.headingsec {
  margin-bottom: 32px;

  @media(max-width:479px) {
    margin-bottom: 24px;
  }

  h2 {
    margin-bottom: 13px;
  }
}

.gapsec {
  padding: 90px 0;

  @media(max-width:1199px) {
    padding: 75px 0;
  }

  @media(max-width:991px) {
    padding: 60px 0;
  }

  @media(max-width:767px) {
    padding: 55px 0;
  }
}


.btn,
.btnwh {
  // width: 100%;
  padding: 14px 35px;
  background: #fff;
  border-radius: 0;

  font-family: 'futuramedium';
  font-weight: normal;
  font-style: normal;
  border: 1px solid #557A95;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #557A95;

  &:hover {
    background: #557A95;
    color: #fff;
  }

  @media(max-width:767px) {
    padding: 13px 25px;
    font-size: 15px;
    line-height: 20px;
  }
}

.btnwh {
  color: #557A95;
  border: 1px solid #D6D6D6;

  &:hover {
    background: #557A95;
    border-color: #557A95;
    color: #fff;
  }
}

a {
  display: inline-block;
  cursor: pointer;
}


.btn:focus,
button.focus {
  box-shadow: none;
}

figure {
  margin: 0 0 15px 0;
}

.gapsm {
  padding: 50px 0;
}

.card-text-header {
  svg {
    width: 34px;
    height: 20px;
    position: relative;
    display: inline-block;
    position: absolute;
    top: 16px;
    left: 15px;

  }
}

.bannerInner {
  padding: 30px;
}

.total-payment {


  .payment-detail {
    // border-bottom: 1px solid #dedede;
    // padding-top: 10px;
    padding: 1rem;
    border-radius: 16px;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #dedede;
    transition: 0.3s all ease-in-out 0s;

    &:hover {

      box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.17);


    }
    .payment-heading{
      &:last-child{
        h3{
          margin-bottom: 0;
        }
      }
    }
  }

  .payment-detail2 {
    padding-top: 10px;
  }
}

.payment-heading {
  h3 {
    color: #5D5C61;

    font-weight: normal;
    font-style: normal;
    padding: 0;
    margin: 0 0 20px 0;
    line-height: 13px;
    font-size: 14px;
    font-family: 'futuramedium';

  }
}

.main-heading {
  display: flex;
  justify-content: space-between;

  h3 {
    font-size: 16px;
    font-weight: 700;
    font-family: 'futuramedium';
  }
}

.success {

  color: #2bcf2b;
  font-family: 'futuramedium';

}

.cart-icon {
  display: flex;
  padding: 20px 10px 45px 10px;
  font-family: 'futuramedium';
}

.cart1 {
  padding: 0px 15px 0px 0px;

  ul {
    li {
      span {
        padding: 0px 15px;
        font-weight: 700;

      }
    }
  }
}

.order-img {
  img {
    height: 358px;
    object-fit: cover;
    width: 100%;
  }
}

.total-pic {}

.order {
  border-radius: 16px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #dedede;
  transition: 0.3s all ease-in-out 0s;

  &:hover {

    box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.17);


  }
}

.download-btn {
  background: #fff;
  border-radius: 0;
  font-family: 'futuramedium';
  font-weight: normal;
  font-style: normal;
  border: 1px solid #557A95;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #557A95;


  &:hover {
    color: #fff;
    background-color: #557A95;
  }
}

.button-back {

  cursor: pointer;

  margin-bottom: 10px;
  color: #2b4257;

  font-size: 13px;

  &:hover {}
}

.privacybox {
  text-align: center;
}

.addloc2 {
  input {

    background: none !important;
    padding-left: 20px !important;
    font-size: 15px;
  }

  .error_txt {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0 !important;
    position: absolute;
    bottom: -15px;
    left: 0;
  }

  .form_input {
    position: relative;
    margin-bottom: 25px;
  }
}

.bedbath {
  margin-left: 40px;
}

.step_forStepfour {
  width: 50%;
  margin-top: 20px;
  margin-right: 15px;
  font-size: 20px;
  padding: 30px 20px;
  background: #f7f7f7;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  min-height: inherit;
  max-height: 314px;
  @media(max-width: 991px){
    width: 100%;
    margin: 0 0 20px;
    max-height: inherit;
  }
}

.designDetail {
  &.stepfourSlide {
    .card {
      flex-direction: row;
      @media(max-width: 767px){
        flex-direction: column;
      }

      .slick-slider {
        width: 50%;
        @media(max-width: 767px){
          width: 100%;
        }

        .slick-arrow {
          background: #ffffff;
          box-shadow: 0px 4px 44px #ffdbe1;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          display: flex !important;
          align-self: center;
          justify-content: center;
          color: #31719c;
          z-index: 9;

          svg {
            width: 12px;
            height: auto;
            margin: auto;
          }

          &.slick-next {
            right: 5px;
          }

          &.slick-prev {
            left: 5px;

            svg {
              left: 2px;
              position: relative;
            }
          }

          &::before {
            display: none;
          }

          &:hover {
            color: #000;
          }
        }

        .slick-slide {
          img {
            height: 250px;
            object-fit: contain;
            object-position: center;
          }
        }
      }

      .card-body {
        width: 50%;
        @media(max-width: 767px){
          width: 100%;
        }
      }
    }

  }

  .slider_cardDetails {
    h5 {
      font-weight: 600;
      font-size: 20px;
      line-height: 1.1;
      text-transform: uppercase;
      color: #31719c;
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 10px;
      font-family: "Cormorant Garamond", serif;
    }

    .bedbath1 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 13px;
      line-height: 36px;
      font-family: "futurabook";
      color: #5d5c61;

      li {
        &:not(:last-child) {
          padding-right: 15px;
        }
      }

      span {
        display: inline-block;
        padding: 0 2px;
      }
    }

    ul {
      li {
        .detailHeading {
          display: inline-block;
          font-size: 13px;
          font-family: "futuramedium";
          color: #000000;

        }

        p {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: 13px;
          font-family: "futurabook";
          color: #5d5c61;
          margin: 15px 0;
        }

        span {
          &.h6 {
            font-size: 13px;
            font-family: "futurabook";
            color: #5d5c61;
          }
        }
      }
    }
  }
}

.react-pdf__Page__annotations {
  .annotationLayer {
    display: none;
  }

}

.signaturesec {
  .btn {
    font-size: 14px;
    padding: 6px 20px;
    line-height: 19px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.addLocation{
.modal-dialog {
  .modal-body{
    .slick-slider{
      height: auto !important;
      &.nav{
        .slick-slide{
          padding: 0 5px;
        img{
          height: 100px;
          object-fit: contain;
          object-position: center;
        }
      }
      }
      .slick-slide{
        img{
          height: 320px;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}
}