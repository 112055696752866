

.cancel-modal-design{
    .modal-header{
        h3{
            text-align: center;
            font-family: "Cormorant Garamond",serif;
            font-weight: 700;
            font-size: 24px;
            line-height: 64px;
            margin-bottom: 0;
        }
    }
    .modal-body{
        .last-btn{
            button{
                width: 100%;
                border-radius: 10px;
                transition: 0.6s all ease-in-out;
                &:first-child{
                    margin-right: 10px;
                }
                &:hover{
                color: #fff;
                background: transparent;
                box-shadow: inset 0 50px 10px #557A95;
                transition: 0.6s all ease;
                }
            }
        }
    }
    
}