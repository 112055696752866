.privacysec {
    padding-bottom: 50px;
    // &.gapsm {
    //   padding-bottom: 0;
    // }
  
    .privacybox {
      position: relative;
      text-align: left;
  
      // padding-top: 100px;
      // margin-bottom: -50px;
      @media(max-width:767px) {
        padding-left: 30px;
      }
  
    //   &:before {
    //     position: absolute;
    //     content: "";
    //     left: 50%;
    //     transform: translateX(-50%);
    //     background: #31719C;
    //     width: 21px;
    //     height: 21px;
    //     border-radius: 50%;
    //     top: 0;
    //     z-index: 1;
  
    //     @media(max-width:767px) {
    //       left: 0;
    //       transform: inherit;
    //     }
    //   }
  
    //   &:after {
    //     position: absolute;
    //     content: "";
    //     left: 50%;
    //     transform: translateX(-50%);
    //     background: #31719C;
    //     width: 21px;
    //     height: 21px;
    //     border-radius: 50%;
    //     bottom: 0;
    //     z-index: 1;
  
    //     @media(max-width:767px) {
    //       transform: inherit;
    //       left: 0;
    //     }
    //   }
  
    
    }
  
  }
  
