.header {
  padding: 15px 40px;
  box-shadow: 0px 4px 16px rgba(198, 217, 230, 0.34);

  @media (max-width: 991px) {
    padding: 15px 20px;
  }

  .headerInner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .logo {
      a {
        display: inline-block;

        img {
          width: 101px;
          object-fit: contain;
        }
      }
    }

    .headerRight {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      width: calc(100% - 101px);

      @media (max-width: 767px) {
        position: fixed;
        left: -100%;
        top: 0;
        width: 250px;
        background: #fff;
        flex-basis: 100%;
        max-width: 100%;
        z-index: 9;
        height: 100vh;
        padding: 50px 20px;
        display: block;
        transition: 0.3s all ease-in-out 0s;
      }

      .closebtn {
        position: absolute;
        top: 20px;
        right: 20px;
        display: none;
        color: #e75959;

        @media (max-width: 767px) {
          display: block;
        }
      }

      .navbarsec {
        padding-left: 50px;

        @media (max-width: 991px) {
          padding-left: 30px;
        }

        @media (max-width: 767px) {
          padding-left: 0;
          margin-bottom: 30px;
        }

        ul {
          display: flex;
          flex-wrap: wrap;

          @media (max-width: 767px) {
            display: block;
          }

          li {
            padding-right: 50px;
            font-size: 18px;
            line-height: 24px;
            font-family: "futuramedium";

            @media (max-width: 991px) {
              padding-right: 20px;
            }

            @media (max-width: 767px) {
              margin: 0 0 15px 0;
              padding: 0;
            }

            &:last-child {
              padding-right: 0;
            }

            a {
              color: #525255;

              &:hover {
                color: #31719c;
              }
            }
          }
        }
      }

      .loginSignup {
        margin-left: auto;

        @media (max-width: 767px) {
          margin-left: 0;
        }

        .loginregbtn {
          display: flex;
          flex-wrap: wrap;

          li {
            margin-right: 8px;

            @media (max-width: 767px) {
              flex-basis: 100%;
              max-width: 100%;
              margin-right: 0;
              margin-bottom: 10px;
            }

            button {
              border-radius: 30px;
              height: 50px;
              width: 140px !important;
              padding: 0px 5px;
              display: flex;
              align-items: center;
              font-family: "futuramedium";
              justify-content: center;
              font-size: 18px;
              line-height: 24px;

              @media (max-width: 767px) {
                width: 100%;
              }

              &.whbtn {
                border: 1px solid #d9d9d9;
                color: #525255;
                background: #fff;

                img {
                  margin-right: 7px;
                }

                &:hover {
                  background: #525255;
                  color: #fff;

                  img {
                    filter: brightness(0) invert(1);
                  }
                }
              }

              &.dropdownbtn {
                padding: 0;
                border: 0;
                color: #000000;

                &:focus {
                  border: 0;
                  box-shadow: none;
                }

                &:hover {
                  background: transparent;
                  color: #000000;

                  img {
                    filter: inherit;
                  }
                }
              }

              &.graybtn {
                background: #525255;
                color: #fff;
                border: 1px solid #525255;

                &:hover {
                  background: #31719c;
                  color: #fff;
                }
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .headerProfile {
          .headerProfileInner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            figure {
              margin-right: 8px;
              margin-bottom: 0;

              img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }
            }

            p {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              text-transform: capitalize;
              color: #000000;
            }
          }
        }
      }
    }
  }
}

.signupModal {
  .modal-content {
    border: 0;
    box-shadow: 0px 10px 55px #000000;
    border-radius: 10px;
  }
}

.signupmodalInner {
  padding: 40px 20px;
  color: #626262;
  font-family: "futurabook";
  text-align: center;

  @media (max-width: 767px) {
    padding: 40px 10px;
  }

  h4 {
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    font-family: "Cormorant Garamond", serif;
    color: #000000;
    margin-bottom: 13px;

    @media (max-width: 767px) {
      font-size: 25px;
      line-height: 30px;
    }
  }

  .btnwh {
    border-radius: 40px;
    width: 100%;
    text-align: center;
  }
}

.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(24px);
  opacity: 1;
}

.menubtn {
  display: none;
  color: #00709a;

  button {
    padding: 0;
  }

  @media (max-width: 767px) {
    display: inline-block;
    margin-left: auto;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  opacity: 0;
  z-index: 8;
  transition: 0.3s all ease-in-out 0s;
}

body {
  &.body-active {
    height: 100vh;
    overflow: hidden;

    .header {
      .headerRight {
        left: 0;
      }
    }

    .overlay {
      width: 100%;
      opacity: 1;
    }
  }
}

.dropdown {
  position: relative;
}

.profiledrop {
  padding: 10px;
  position: absolute;
  right: 0 !important;
  top: 55px !important;
  z-index: 999 !important;
  transform: inherit !important;
  width: 210px;

  // inset: initial !important;
  a {
    color: #525255;
    font-family: "futuramedium";
    font-size: 14px;
    line-height: 20px;
    padding: 8px 5px;

    svg {
      margin-right: 5px;
      width: 22px;
      height: auto;
    }
  }
}
.dropdown-item.active,
.dropdown-item:active {
  color: black !important;
  text-decoration: none;
  background-color: #e9ecef !important;
}
.header_active {
  color: #31719c !important;
}
