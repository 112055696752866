.card-text-header{
  position: relative;
  input{
    padding: 0 50px;
    font-weight: 600;
    
  }
  svg * {
    
    transform: scale(.2);
}
}
.card-img{
  position: absolute;
  height: 24px;
  width: 24px;
  top:14px;
  left:10px;
}

.payment-btn-padding{
  margin-top: 30px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.card-text-header .default-svg svg *{
  transform: scale(1.2);
  width: 50px;
}






.new_all_payment-page-sec{

  .modal-content{
    border: none;
  }
  .modal-header{
    border-radius: 10px 10px 0 0;
  }
  .modal-body{
    border-radius: 0 0 10px 10px;
  }
  .default-svg{
    // position: absolute;
    // top: 40px;
    // left: 8px;
    svg{
      position: initial;
    }
  }
  .card-text-header svg{
    position: absolute;
    top: 42px;
    left: 10px;
  }


  .card-txt-footer{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 10px;
  }

  .all-flex_card-payment{
    display: flex;
    align-items: flex-end;
    max-width: 63%;
    .form-group-payment{
      margin-bottom: 0;
      position: relative;
      &:nth-child(1){
        input{
          width: 100% !important;
        }
      }
      &:nth-child(2){
        margin-left: 15px;
        input{
          width: 100% !important;
        }
      }
    }
  }
  .form-group-payment{
    margin-bottom: 15px;
    label{
      color: rgb(0, 112, 154);
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 8px;
      padding-left: 8px;
    }
    
  }

  .new_btn_last_payment-pay{
    border-radius: 5px;
    transition: 0.6s all ease-in-out;
    &:hover{
      color: #fff;
      background: transparent;
      box-shadow: inset 0 50px 10px #557A95;
      transition: 0.6s all ease;
    }
  }
} 

.valid-err{
  position: absolute;
  font-size: 12px;
  bottom: -24px;
}