.workstepsec {
  &.gapsm {
    padding-bottom: 0;
  }

  .workStep {
    position: relative;

    // padding-top: 100px;
    // margin-bottom: -50px;
    @media(max-width:767px) {
      padding-left: 30px;
    }

    &:before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      background: #31719C;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      top: 0;
      z-index: 1;

      @media(max-width:767px) {
        left: 0;
        transform: inherit;
      }
    }

    &:after {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      background: #31719C;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      bottom: 0;
      z-index: 1;

      @media(max-width:767px) {
        transform: inherit;
        left: 0;
      }
    }

    .item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 50px;
      position: relative;

      &:nth-child(1) {
        padding-top: 100px;

        @media(max-width:991px) {
          padding-top: 70px;
        }
      }

      .imagebox {
        flex-basis: 40%;
        max-width: 40%;

        @media(max-width:991px) {
          flex-basis: 45%;
          max-width: 45%;
        }

        @media(max-width:767px) {
          flex-basis: 100%;
          max-width: 100%;
          margin-bottom: 30px;
        }

        figure {
          margin-bottom: 0;

          img {
            width: 100%;
          }
        }
      }

      .line {
        border-right: 1px solid #C2C2C2;
        width: 1px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;

        @media(max-width:767px) {
          transform: inherit;
          left: -20px;
        }
      }

      .content {
        flex-basis: 45%;
        max-width: 45%;

        @media(max-width:767px) {
          flex-basis: 100%;
          max-width: 100%;
        }

        .number {
          background: #898388;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-bottom: 30px;
          color: #fff;
          font-size: 26px;
          line-height: 30px;
          text-align: center;
          color: #FFFFFF;
          position: relative;

          &:before {
            position: absolute;
            content: "";
            left: -69px;
            background: #608CA2;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            top: 20px;
            z-index: 1;

            @media(max-width:1399px) {
              left: -60px;
            }

            @media(max-width:1199px) {
              left: -52px;
            }

            @media(max-width:991px) {
              left: -40px;
            }

            @media(max-width:767px) {
              left: -25px;
            }
          }
        }

        h4 {
          color: #5D5C61;
          font-family: 'futuramedium';
          font-size: 24px;
          line-height: 24px;
        }

        p {
          font-family: 'futurabook';
        }
      }

      &:nth-child(even) {
        .imagebox {
          order: 1;

          @media(max-width:767px) {
            order: -1;
          }
        }

        .content {
          .number {
            position: static;

            @media(max-width:767px) {
              position: relative;
            }

            &:before {
              position: absolute;
              content: "";
              left: 50%;
              transform: translateX(-50%);
              background: #608CA2;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              top: 20px;
              z-index: 1;

              @media(max-width:767px) {
                transform: inherit;
                left: -25px;
              }
            }
          }
        }
      }
    }
  }

}

.faqsec {
  .accordion-item {
    background: #FFFFFF;
    box-shadow: none;
    // border-radius: 30px !important;
    margin-bottom: 20px;
    border: 0;

    &:last-child {
      margin-bottom: 0;
    }

    .accordion-header {
      margin-bottom: 0;


      button {
        box-shadow: none;
        padding: 18px 25px;
        font-family: 'Cormorant Garamond', serif;
        background: #FFFFFF !important;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #101A34;
        font-weight: 700;

        border: 1px solid #F1F1F1;
        border-radius: 10px !important;

        @media(max-width:767px) {
          font-size: 15px;
          line-height: 22px;
          padding: 14px 15px;
        }

        .number {
          color: #A8ACB3;
          display: inline-block;
          margin-right: 6px;
        }

        &:focus {
          outline: 0;
          box-shadow: none;
        }

        &:after {
          color: #000;
          width: 15px;
          height: 15px;
          background-size: 100% 100%;
        }

        // &::after {
        //   background: transparent;
        //   content: "";
        //   width: 32px;
        //   height: 32px;
        //   background: url(/assets/images/plusic.svg) no-repeat center center;
        // }

        // &:not(.collapsed)::after {
        //   transform: inherit;
        //   background: url(/assets/images/minusic.svg) no-repeat center center;
        // }
      }
    }

    .accordion-body {
      color: #5A5A5A;
      padding: 35px 20px;
      background: linear-gradient(180deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%);

      @media(max-width: 767px) {
        font-size: 13px;
        line-height: 24px;
      }
    }
  }
}

.readytostart {
  background: #31719C;
  color: #fff;
  text-align: center;
  // margin: 0 15px;
  padding: 60px 0;
  h2 {
    color: #fff;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
  }

  p {
    color: #9AD6FF;
    font-family: 'futurabook';
  }

  .btn {
    border-radius: 50px;
    color: #3F3F3F;
    border-color: #fff;

    &:hover {
      background: #3F3F3F;
      color: #fff;
      border-color: #3F3F3F;
    }
  }
}