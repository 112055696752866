.innerBanner {
  position: relative;
  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #31719C;

  @media(max-width:991px) {
    min-height: 300px;
  }

  &:before {
    background: #3678A5;
    border-radius: 550px 0px 0px 0px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 100%;

    @media(max-width:767px) {
      width: 100%;
      border-radius: 50% 50% 0 0;
      height: 50%;
    }

  }

  figure {
    margin-bottom: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;

    @media(max-width:767px) {
      position: static;
      width: 100%;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      object-position: right;

      @media(max-width:991px) {
        height: 300px;
      }

      @media(max-width:479px) {
        height: 150px;
      }
    }
  }

  .bancont {
    position: relative;
    z-index: 1;

    @media(max-width:767px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .innerbancont {
      max-width: 490px;
      color: #fff;

      @media(max-width:1199px) {
        max-width: 400px;
      }

      @media(max-width:991px) {
        max-width: 345px;
      }

      h1 {
        font-family: 'Cormorant Garamond', serif;
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 10px;
        color: #FFFFFF;

        @media(max-width:991px) {
          font-size: 35px;
          line-height: 39px;
        }
      }
    }
  }
}

.aboutsec1 {

  .inneraboutsec1 {
    // border-bottom: 1px solid #D7D6D6;
    padding-bottom: 50px;
  }

  .lftsec {
    padding-right: 80px;

    @media(max-width:991px) {
      padding-right: 0;
      margin-bottom: 40px;
    }
  }

  .row {
    // align-items: center;
  }


  figure {
    @media(max-width:991px) {
      text-align: center;
      margin-bottom: 0;
    }

    img {
      width: 85%;

      @media(max-width:991px) {
        width: 55%;
        margin: 0 auto;
      }
    }
  }
}

.about2nd {
  overflow: hidden;
  position: relative;
  border-radius: 0px 0px 200px 0px;

  @media(max-width:1199px) {
    border-radius: 0px 0px 100px 0px;
  }

  @media(max-width:991px) {
    border-radius: 0 0px 50px 50px;
  }

  @media(max-width:767px) {
    margin-bottom: 50px;
  }

  .leftsec {
    padding-right: 0;

    @media(max-width:991px) {
      padding-right: 15px;
      margin-bottom: 40px;
      text-align: center;
    }

  }

  .about2ndRight {
    background: #F9F9F9;

    position: relative;

    &:before {
      position: absolute;
      content: "";
      right: -1920px;
      top: 0;
      height: 100%;
      width: 1920px;
      background: #F9F9F9;

    }

    @media(max-width:991px) {
      &:after {
        position: absolute;
        content: "";
        left: -1920px;
        top: 0;
        height: 100%;
        width: 1920px;
        background: #F9F9F9;

      }
    }

    .innerabout2ndRight {
      padding: 30px 50px;
      padding-bottom: 42px;
      padding-right: 0;

      @media(max-width:991px) {
        padding-left: 0;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -30px;

        li {
          flex-basis: 48%;
          max-width: 48%;
          margin-bottom: 30px;

          @media(max-width:479px) {
            flex-basis: 100%;
            max-width: 100%;
          }

          figure {
            background: #EEEEEE;
            width: 82px;
            height: 82px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            border-radius: 50%;

          }

          h3 {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.01em;
            text-transform: capitalize;
            font-family: 'Cormorant Garamond', serif;
            color: #222222;

          }
        }
      }
    }
  }
}



.ourmissionsec {
  padding: 200px 0 90px 0;

  @media(max-width:991px) {
    padding: 60px 0 70px 0;
  }

  @media(max-width:767px) {
    background-color: rgba(49, 113, 156, 0.69) !important;
    padding: 55px 0 55px 0;
  }

  .innerourmissionsec {
    max-width: 832px;
    margin: 0 auto;
    text-align: center;
    color: #fff;

    @media(max-width:991px) {
      position: relative;
      top: 130px;
      padding-bottom: 130px;
    }

    @media(max-width:767px) {
      position: relative;
      top: 0;
      padding-bottom: 0;
    }

    h2 {
      color: #fff;
    }

    .btn {
      background: #fff;
      border-color: #fff;
      color: #557A95;

      &:hover {
        background: #557A95;
        color: #fff;
        border-color: #557A95;
      }
    }
  }
}

.teamsec {
  .headingsec {
    text-align: center;
  }

  .row {
    @media(max-width:991px) {
      margin-bottom: -30px;

      >div {
        margin-bottom: 30px;
      }
    }

    figure {
      margin-bottom: 20px;
      text-align: center;

      img {
        width: 220px;
        height: 220px;
        object-fit: cover;
        border-radius: 50%;
        margin: 0 auto;

        @media(max-width:1199px) {
          width: 180px;
          height: 180px;
        }
      }
    }

    h4 {
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      text-transform: capitalize;
      color: #5D5C61;
      font-family: 'futuramedium';
      margin-bottom: 8px;
    }

    p {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 3px;
      color: #00709A;
    }
  }
}

.ourvisionsec {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(49, 113, 156, 0.69);
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .innerourmissionsec {
    max-width: 832px;
    margin: 0 auto;
    text-align: center;
    color: #fff;

    h2 {
      color: #fff;
    }

    .btn {
      background: #fff;
      border-color: #fff;
      color: #557A95;

      &:hover {
        background: #557A95;
        color: #fff;
        border-color: #557A95;
      }
    }
  }
}